import React from "react";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { HeadingFormat } from "../Panel/panelroutes";
import "./Fallback.css"
const FallbackList = ({ title = "Record", icon = <></> }) => {
  return (
    <div
    className="fallback-wrapper"
    >
      <div>
        <NoteAddIcon sx={{ fontSize: 50 }}/>
      </div>
      <h3 style={{textAlign:"center"}}>Currently No {HeadingFormat(title)} Exist!</h3>
      <p className="animate-charcter" >Create A New {HeadingFormat(title)}.</p>
    </div>
  );
};

export default FallbackList;
