
const SelectedStorage = sessionStorage;

export const globalState = {
    set:
        (_StateProps) =>
        (key, value, prefixIncluded = false) => {
            if (!prefixIncluded) {
                if (_StateProps && _StateProps?.user_id) {
                    key = _StateProps.user_id.toString() + '_' + key;
                }
            }
            SelectedStorage.setItem(key, value);
        },
    get:
        (_StateProps) =>
        (key, prefixIncluded = false) => {
            if (!prefixIncluded) {
                if (_StateProps && _StateProps?.user_id) {
                    return SelectedStorage.getItem(
                        _StateProps.user_id.toString() + '_' + key
                    );
                }
            }
            return SelectedStorage.getItem(key);
        },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeLocalStorage:
        (_StateProps) =>
        (key, path = '/') => {
            if (_StateProps && _StateProps?.user_id) {
                key = _StateProps.user_id.toString() + '_' + key;
            }
            return SelectedStorage.removeItem(key);
        },
    getBearerToken: (_StateProps) => () => {
        if (_StateProps && _StateProps?.user_id) {
            return SelectedStorage.getItem(
                _StateProps.user_id.toString() + '_' + 'auth_token'
            );
        }
        return process.env.REACT_APP_BEARER;
    },
    prepareQuery: (params) => {
        let queryString = Object.keys(params).length > 0 ? '?' : '';
        const end = '';
        for (let i = 0; i < Object.keys(params).length; i++) {
            let key = params[Object.keys(params)[i]];
            queryString += end + key + '=' + params[key];
            key = '&';
        }
        return queryString;
    },
};