/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback, useContext} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {MyContext} from '../staticsData/MyContext'
import {noRef} from '../components/utility/GlobalFunctons'
import {initialState} from '../staticsData/initialData'
import {globalState} from '../Services'
import ErrorBoundary from './ErrorBounderies'
import { useSnackbar } from 'notistack'

const message = `Sorry, the request was unsuccessful. Please try again later.`
const DependencyInjection = function (
  Component,
  {func = {}, stateNeeded = true} = {},
  errorType = ''
) {
  const ComponentWrapper = function (props) {
    let navigate = useNavigate()
    let match = useParams()
    let location = useLocation()
    const {enqueueSnackbar} = useSnackbar()
    const {centralState, setCentralState} = useContext(MyContext)
const error=(msg)=>{
    enqueueSnackbar(msg??'error!', {
        preventDuplicate: false,
        variant: 'error',
        autoHideDuration: 4000
      })
}
const success=(msg)=>{
    enqueueSnackbar(msg??'success', {
        preventDuplicate: false,
        variant: 'success',
        autoHideDuration: 4000
      })
}
    function prepareheaders(StateProps) {
      return {
        Authorization: 'Bearer ' + globalState.getBearerToken(StateProps)()
      }
    }
    const GET = async (endpoint, params={}, fullUrl = false) => {
      let paramsString = ''
      if (endpoint.search('\\?') >= 0) paramsString += '&'
      else paramsString += '?'

      for (let i = 0; i < Object.keys(params).length; i++) {
        const end = i < Object.keys(params).length - 1 ? '&' : ''
        paramsString +=
          Object.keys(params)[i] + '=' + encodeURIComponent(params[Object.keys(params)[i]]) + end
      }

      let url = process.env.REACT_APP_API_END_POINT + endpoint
      if (fullUrl) {
        url = endpoint
      }
      return fetch(url + paramsString, {
        method: 'GET',
        headers: prepareheaders(centralState.session)
      })
        .then((res) => res.json())
        .then((e) => {
          validateResponse(e)
          return responseModifier(e)
        })
        .catch((e) => {
          return {success: false, message, code: e}
        })
    }
    const POST = async (endpoint, data, fullUrl = false) => {
      let url = process.env.REACT_APP_API_END_POINT + endpoint
      if (fullUrl) {
        url = endpoint
      }
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...prepareheaders(centralState.session)
        },
        body: JSON.stringify({
          ...data
        })
      })
        .then((res) => res.json())
        .then((e) => {
          validateResponse(e)
          return responseModifier(e)
        })
        .catch((e) => {
          return {success: false, message, code: e}
        })
    }
    const validateResponse = (e) => {
      if (
        e.code === 'token_expired' ||
        e.code === 'invalid_token' ||
        e.code === 'future_token' ||
        e.code === 'token_decode_error'
      ) {
        setCentralState(noRef(initialState))
      }
    }
    function responseModifier(res) {
      if (!res['success'] || res['errorFlag'] !== undefined) {
        res['success'] = false
        if (!res['message'] && res['msg']) {
          res['message'] = res['msg']
        }
      }
      return res
    }
    const loginProcess = useCallback((res) => {
        globalState.set( {
            token: res.token,
            ...res.data,
            user_id: res.data.id
          })('auth_token', res.token)
      setCentralState((prev) => {
        return {
            ...prev,
            session: {
                token: res.token,
                ...res.data,
                user_id: res.data.id
              }
        }
      })
    }, [centralState.session])
    const logoutProcess = useCallback(() => {
      setCentralState(noRef(initialState))
      globalState.removeLocalStorage(centralState.session)('auth_token')
    }, [centralState.session])
    const history=(route)=>{
        navigate(route)
    }
    const imgPath=useCallback((img)=>process.env.REACT_APP_IMAGES_END_POINT+img,[])
    let di = {
      setState: setCentralState,
      state: centralState,
      history,
      imgPath:imgPath,
      globalState: {
        set: globalState.set(centralState.session),
        get: globalState.get(centralState),
        removeLocalStorage: globalState.removeLocalStorage(centralState.session),
        getBearerToken: globalState.getBearerToken(centralState.session)
      },
      user_id: match?.uId ?? centralState?.session?.user_id,
      session:centralState.session,
      GET,
      POST,
      loginProcess,
      logoutProcess,
      error,
      success,
      ...func
    }
    if (stateNeeded) di = {...di, ...func}
    return (
      <React.Fragment>
        <ErrorBoundary history={navigate} comp={Component} errorType={errorType}>
          <Component {...props} history={navigate} location={location} match={match} di={di} />
        </ErrorBoundary>
      </React.Fragment>
    )
  }

  return ComponentWrapper
}

export default DependencyInjection

export {DependencyInjection as DI}
