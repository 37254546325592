import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createMuiTheme } from '@mui/material';
const root = ReactDOM.createRoot(document.getElementById('root'));
export const themecolor={
    primary:'#4155A2',
    secondary:'#DDE6ED',
    default:'#DDE6ED',
    color1: '#4155A2',
    color1light: '#dce3ff',
    color2: '#FF2171',
    color2light:'#ffdce9',
}
const theme = createMuiTheme({
    palette: {
       primary: {
          main: themecolor.primary// This is an orange looking color
                 },
       secondary: {
          main: themecolor.secondary //Another orange-ish color
                  }
             },
 });
root.render(
<ThemeProvider theme={theme}>
<App />
</ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
