import React from "react";
import { DI } from "../../Core/DependencyInjecttion";

const QuestionBox = (_props) => {
  const { question, number, answerChhose,selection }=_props
  return (
    <>
      <div className="test_quesion_number ">Question No. {number}</div>
      <div className="test_questionbox bgrey">
        <>
          {question?.bilingual && (
            <div className="test_hindi test_question_text">
                  <div
            dangerouslySetInnerHTML={{ __html: question?.question?.hindi }}
          />
            </div>
          )}
          <div className="test_english test_question_text">
          <div
            dangerouslySetInnerHTML={{ __html: question?.question?.english }}
          />
          </div>
          {question?.imageLink && (
            <img
              style={{ maxWidth: "200px", maxHeight: "400px" }}
              src={_props.di.imgPath(question?.imageLink)}
              alt="additional_image_Expired"
            />
          )}
          <div className="test_optionbox">
            {Array(4)
              .fill("")
              .map((item, index) => (
                <div
                  key={index + JSON.stringify(item)}
                  className="test_optionwrapper"
                  style={{alignItems:'start'}}
                >
                  <input
                    type="checkbox"
                    id="option1"
                    onChange={() => answerChhose(index)}
                    checked={index === selection}
                  />
                  <div className="test_option_label">
                    {question.bilingual && (
                      <span className="test_option_label test_question_text">
                        {question.options.hindi[index]}
                      </span>
                    )}
                    <span className="test_english test_question_text">
                      {question.options.english[index]}
                    </span>
                    { question?.options?.images?.[index]&&<img
                style={{marginTop:'8px'}}
              src={_props.di.imgPath(question.options.images[index])}
              className="image_preview"
              alt="faulty pic."
              accept="image/*"
            />}
                  </div>
                </div>
              ))}
          </div>
        </>
      </div>
    </>
  );
};

export default DI(QuestionBox);
