import { api_getPapers } from "../staticApis/getPapers";

export const initialState = {
  "exams": [
      {
          "id": "exams-7355",
          "exam_name": "CHSL",
          "exam_type": "Central Gov.",
          "created_at": "2023-08-08 16:09:06"
      },
      {
          "id": "exams-1437",
          "exam_name": "CGL",
          "exam_type": "Central Gov.",
          "created_at": "2023-08-08 16:09:17"
      },
      {
          "id": "exams-9948",
          "exam_name": "UPSC",
          "exam_type": "Central Gov.",
          "created_at": "2023-08-08 16:09:25"
      },
      {
          "id": "exams-7603",
          "exam_name": "Demo",
          "exam_type": "Others",
          "created_at": "2023-08-10 13:46:29"
      }
  ],
  "papers": api_getPapers.data,
  "users": [],
  session:{
  }
}

export const status_details = [
  {
    id: 0,
    status: "Answered",
  },
  {
    id: 1,
    status: "Not Answered",
  },
  {
    id: 2,
    status: "Not Visited",
  },
  {
    id: 3,
    status: "Mark for Review",
  },
  {
    id: 4,
    status: "Answered & Marked for Review (Will not considered for evaluation)",
  },
];

export const homePage={
    pricing_plans:[
        {
            plan_id:"2221",
            color:'#4155A2',
            plan_name:"Basic",
            price: 300,
            duration :'Per month',
            features:[
                '*1 moc test* daily',
                'for *specific Exam *',
                '*3gb* disk Space',
            ],
          cap:'Free',
          buttonName:"buy now"
        },
        {
            plan_id:"2223",
            color:'#4155A2',
            plan_name:"Advance",
            price: 900,
            duration:'3 month',
            features:[
                '*5 moc test* daily',
                'for *2 Exams *',
                '*3gb* disk Space',
            ],
          cap:'Free',
          buttonName:"buy now"
        },{
            plan_id:"2222",
            plan_name:"Premium",
            color:'#4155A2',
            price: 1500,
            duration:'Per year',
            features:[
                '*UNLIMITED* test',
                '*For all exams*',
                '*3gb* disk Space',
            ],
          cap:'Free',
          buttonName:"buy now"
        }
    ]
}