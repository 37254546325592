import React from 'react';
import ErrorPage from './ErrorPage';
class ErrorBoundary extends React.Component {
    static props;
    constructor(props) {
        super(props);
        this.state = { hasError: false, message: '' };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error) {
        this.props.error(error.toString(), this.props.errorType);

        // You can also log the error to an error reporting service
        this.setState({ message: error.toString() });
    }

    render() {
        const { message } = this.state;
        if (this.state.hasError) {
            return (
                <div className="custom_session_expire after_login_broken">
                    <div className="inner_s">
                        <ErrorPage />
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;