import {FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {MyContext} from '../../staticsData/MyContext'
import {HeadingFormat} from './panelroutes'
import Loading from '../Fallback/Loading'
import {useSearchParams} from 'react-router-dom'
import PropTypes from 'prop-types'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
const ShowQuestion = (_props) => {
  // const {
  // centralState: { papers },
  // } = React.useContext(MyContext);

  const [papers, setPapers] = React.useState([])
  const [searchParams] = useSearchParams()
  const paperId = searchParams.get('paperId')
  const [state, setState] = useState({
    loading: true,
    questions: []
  })
  const [selected, setSelected] = useState(paperId ? paperId : '')

  useEffect(() => {
    if (selected) getQuestionsFromPaper(selected)
  }, [selected])

  React.useEffect(() => {
    getPapers()
  }, [])
  const getPapers = () => {
    setState((prev) => {
      return {...prev, loading: true}
    })
    _props.di
      .GET(urlFetchCalls.get.get_papers)
      .then((res) => {
        if (res.success) {
          setPapers(res?.data ?? [])
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() =>
        setState((prev) => {
          return {...prev, loading: false}
        })
      )
  }
  const getQuestionsFromPaper = (paper_id) => {
    setState((prev) => {
      return {...prev, loading: true}
    })
    _props.di
      .POST(urlFetchCalls.get.get_questions, {
        paper_id: paper_id
      })
      .then((res) => {
        if (res.success) {
          setState((prev) => {
            return {...prev, questions: res.data}
          })
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() =>
        setState((prev) => {
          return {...prev, loading: false}
        })
      )
  }

  const {loading, questions} = state

  if (loading) return <Loading />
  return (
    <>
      <h3 style={{color: 'var(--color2)', marginBottom: '10px'}}>Select Paper :</h3>
      <Grid xs={12}>
        <FormControl sx={{minWidth: 120}} fullWidth size="small">
          <InputLabel id="demo-select-small-label">Choose a Paper to view</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value)
            }}
            label="Choose a Paper to view"
            placeholder="choose one"
            required
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {papers.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {HeadingFormat(option.paper_name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <br />
      <Grid sx={12}>
        {questions?.map((item, index) => (
          <div
            style={{padding: '15px 10px', borderBottom: '1px solid grey'}}
            key={JSON.stringify(item)}
          >
            <h3>Question No. {index + 1}</h3>
            <Question question={item} />
          </div>
        ))}
      </Grid>
    </>
  )
}

export default DI(ShowQuestion)

export const Question = DI((_props) => {
  const {question,di:{imgPath}}=_props
  return (
    <>
      {question?.bilingual && (
        <div className="test_hindi test_question_text">
          <div dangerouslySetInnerHTML={{__html: question?.question?.hindi}} />
        </div>
      )}
      <div className="test_english test_question_text">
        <div dangerouslySetInnerHTML={{__html: question?.question?.english}} />
      </div>
      {question?.imageLink && (
        <img
          style={{maxWidth: '200px', maxHeight: '400px'}}
          src={imgPath(question?.imageLink)}
          alt="additional_image_Expired"
        />
      )}
      <div className="test_optionbox">
        {Array(4)
          .fill('')
          .map((item, index) => (
            <div key={index} className="test_optionwrapper" style={{alignItems:'start'}}>
              <input
                type="checkbox"
                id="option1"
                className={`${index == question.correct_answer ? 'correct_answer' : ''}`}
                checked={question.selectedAnswer == index}
              />
              <div
                className={`${
                  index == question.correct_answer
                    ? 'test_option_label correct_answer'
                    : 'test_option_label'
                }`}
              >
                {question.bilingual && (
                  <span className="test_option_label test_question_text">
                    {question.options.hindi[index]}
                  </span>
                )}
                <span className="test_english test_question_text">
                  {question.options.english[index]}
                </span>
                <span>
                { question?.options?.images?.[index]&&<img
                style={{marginTop:'8px'}}
              src={imgPath(question.options.images[index])}
              className="image_preview"
              alt="faulty pic."
              accept="image/*"
            />}
                </span>
              </div>
           
            </div>
          ))}
      </div>
      {question?.question?.explaination && (
        <details className="answer_explain">
          <summary className="answer_heading">Explaination:</summary>
          <div className="answer_explaination_box">
            <div dangerouslySetInnerHTML={{__html: question?.question?.explaination}} />
          </div>
        </details>
      )}
    </>
  )
})
