import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
import ButtonLoader from '../Common/ButtonLoader'

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme()

function SignIn(props) {
  const {
    di: {POST}
  } = props
  const [loading,setLoading]=React.useState(false);
  const handleSubmit = (event) => {
    setLoading(true)
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    POST(urlFetchCalls.post.login, {
      email: data.get('email'),
      password: data.get('password')
    }).then((res) => {
      if (res.success) {
        props.di.loginProcess(res)
        props.di.success(res?.message)
        props.di.history('/')
      } else {
        props.di.error(res?.message)
      }
    }).finally(()=>setLoading(false))
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ButtonLoader loading={loading} type="submit"  fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
            Sign In
            </ButtonLoader>
         
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="#"
                  variant="body2"
                  onClick={(e) => {
                    e.preventDefault()
                    props.di.history('/register')
                  }}
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
export default DI(SignIn)
