import React, { useEffect, useState } from "react";

const CounterLoading = (props) => {
  const [counter, setCounter] = useState(props.timer);
  useEffect(() => {
    let interval = "";

    if (counter > 0) {
      interval = setInterval(() => {
        setCounter((prev) =>prev?prev-1:prev);
      }, 1000);
    } else {
      clearInterval(interval);
      props.callBack()
    }
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div
      style={{
        minHeight: "100px",
        minWidth: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        flexWrap: "nowrap",
        justifyContent: "center",
        borderRadius: "5px",
        color: "var(--color2)",
        ...props.style,
      }}
    >
      <div className="pie-wrapper"
      style={{background:`radial-gradient(closest-side, white 80%, transparent 80% 100%),conic-gradient(var(--color1) ${100-((100*counter)/props.timer)}%, white 0)` }}
      >
        <span style={{fontSize:"1.8rem",color:`${counter<4?"red":"var(--color1)"}`}}><b>{counter}</b></span>
      </div>
      <p style={{marginTop:"20px",color:"var(--color1)"}}>
        <b>{props.message}</b>
      </p>
    </div>
  );
};

export default CounterLoading;
