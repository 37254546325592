import React, {useEffect, useState} from 'react'
import './Test.css'
import QuestionBox from './QuestionBox'
import QuestionNavigation from './QuestionNavigation'
import {Box, Button, LinearProgress, Modal, Typography} from '@mui/material'
import {createdAt, noRef, secondsToTime} from '../utility/GlobalFunctons'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {MyContext} from '../../staticsData/MyContext'
import {useSnackbar} from 'notistack'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
const Test = (_props) => {
  const {enqueueSnackbar} = useSnackbar()
  const {userId, paperId} = useParams()
  const navigate = useNavigate()
  let {
    centralState: {users},
    setCentralState
  } = React.useContext(MyContext)
  const session = _props?.di?.state?.session

  const [currentPaper, setCurrentPaper] = useState()
  const [timer, setTimer] = useState()
  const [state, setState] = useState({
    questions: [],
    currentQuestion: 0,
    currentSelection: -1,
    userId: userId,
    open: false
  })
  const {questions, currentQuestion} = state
  useEffect(() => {
    if (!Object.keys(session).length) navigate('/login')
    getQuestionsFromPaper(paperId)
  }, [])
  const getQuestionsFromPaper = (paper_id) => {
    setState((prev) => {
      return {...prev, loading: true}
    })
    _props.di
      .POST(urlFetchCalls.get.get_questions, {
        paper_id: paper_id
      })
      .then((res) => {
        if (res.success) {
          if (res?.paper)
            setCurrentPaper({
              ...res?.paper,
              subjects: JSON.parse(res?.paper?.subjects)?.subjects ?? null
            })
          setTimer(parseInt(res?.paper?.total_time_minutes) * 60)
          setState((prev) => {
            return {
              ...prev,
              questions: noRef(makeQuestion(res?.data, res?.paper?.no_of_questions) ?? [])
            }
          })
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() => {
        setState((prev) => {
          return {...prev, loading: false}
        })
        setLoading(false)
      })
  }
  /**
   * timer is in second
   */
  const [loading, setLoading] = useState(true)
  const handleOpen = () =>
    setState((prev) => {
      prev.open = true
      return {...prev}
    })
  const handleClose = () =>
    setState((prev) => {
      prev.open = false
      return {...prev}
    })

  /**
   * start timer
   */
  useEffect(() => {
    let interval = null
    if (!loading) {
      interval = setInterval(() => {
        setTimer((prev) => (prev ? prev - 1 : prev))
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [loading])

  useEffect(() => {
    if (timer === 0) {
      submitPaper()
    }
  }, [timer])

  const answerChhose = (index) => {
    setState((prev) => {
      prev.currentSelection = index
      // prev.questions[prev.currentQuestion].selectedAnswer = index;
      return {...prev}
    })
  }

  const clearResponse = () => {
    setState((prev) => {
      prev.questions[prev.currentQuestion].selectedAnswer = -1
      prev.currentSelection = -1
      prev.questions[prev.currentQuestion].statusId = 1
      return {...prev}
    })
  }

  const nextQuestion = () => {
    if (state.currentSelection != -1)
      enqueueSnackbar('Saved', {
        preventDuplicate: false,
        variant: 'success',
        autoHideDuration: 900
      })
    setState((prev) => {
      prev.questions[prev.currentQuestion].selectedAnswer = prev.currentSelection
      prev.questions[prev.currentQuestion].statusId = prev.currentSelection === -1 ? 1 : 0
      if (currentQuestion < questions.length - 1) {
        prev.currentQuestion = prev.currentQuestion + 1
        prev.currentSelection = prev.questions[prev.currentQuestion].selectedAnswer
      }
      if (prev.questions[prev.currentQuestion].statusId === 2)
        prev.questions[prev.currentQuestion].statusId = 1
      return {...prev}
    })
  }

  const navigateQuestion = (index) => {
    setState((prev) => {
      prev.currentQuestion = index
      let x = prev.questions[prev.currentQuestion].statusId
      prev.questions[prev.currentQuestion].statusId = x === 2 ? 1 : x
      prev.currentSelection = prev.questions[prev.currentQuestion].selectedAnswer
      return {...prev}
    })
  }

  const markForReview = () => {
    setState((prev) => {
      prev.questions[prev.currentQuestion].statusId = prev.currentSelection === -1 ? 3 : 4
      prev.questions[prev.currentQuestion].selectedAnswer = prev.currentSelection
      if (currentQuestion < questions.length - 1) {
        prev.currentQuestion = prev.currentQuestion + 1
        prev.currentSelection = prev.questions[prev.currentQuestion].selectedAnswer
      }
      if (prev.questions[prev.currentQuestion].statusId === 2)
        prev.questions[prev.currentQuestion].statusId = 1
      return {...prev}
    })
  }

  const submitPaper = () => {
    if (userId) {
      enqueueSnackbar('Submitted successfully', {
        preventDuplicate: false,
        variant: 'success'
      })
      setTimeout(() => {
        navigate(`../result/${userId}/${paperId}`, {
          state: {
            submission: {
              questions: [...noRef(state.questions)],
              time_taken: secondsToTime(timer),
              created_at: createdAt()
            },
            paper: currentPaper,
            user: session
          }
        })
      }, 500)
    } else {
      navigate('/')
    }
  }

  return (
    <div className="test_main">
      {loading ? (
        <div style={{width: '100vw'}}>
          <LinearProgress />
        </div>
      ) : (
        <>
          <div className="test_headbar">
            <p className="test_headexamname">
              {currentPaper?.exam_name?.toUpperCase() +
                ' : ' +
                currentPaper?.paper_name?.toUpperCase()}
            </p>
          </div>
          <section className="test_wrapper">
            <div className="test_questionsection">
              <div className="test_timer">
                <p>Section</p>
                <p className="test_stopwatch">
                  Time Left :
                  <span
                    style={{
                      color: timer > 300 ? 'var(--color2)' : 'red',
                      marginLeft: '10px'
                    }}
                  >{`  ${secondsToTime(timer)}`}</span>{' '}
                </p>
              </div>
              {state.questions.length > 0 && (
                <QuestionBox
                  question={questions[currentQuestion]}
                  number={currentQuestion + 1}
                  answerChhose={answerChhose}
                  selection={state.currentSelection}
                />
              )}
              <div className="test_footer footer-btns">
                <div className="test_mark_clear">
                  <Button onClick={markForReview} variant="outlined">
                    Mark For Review & Next
                  </Button>
                  &nbsp; &nbsp;
                  <Button onClick={clearResponse} variant="outlined">
                    Clear Response
                  </Button>
                </div>
                <div className="test_save_button">
                  <Button variant="contained" onClick={nextQuestion}>
                    {currentQuestion < questions.length - 1 ? 'Save & Next' : 'Save'}
                  </Button>
                </div>
              </div>
            </div>
            <div className="test_navigateQuestion">
              <QuestionNavigation
                currentQuestion={currentQuestion}
                navigateQuestion={navigateQuestion}
                questions={questions}
                handleOpen={handleOpen}
              />
            </div>
          </section>
        </>
      )}
      <Modal
        open={state.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography id="modal-modal-title" component="p">
            Are you sure want to submit?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
              marginTop: '20px'
            }}
          >
            <Button variant="contained" onClick={handleClose}>
              CLOSE
            </Button>
            <Button variant="contained" onClick={submitPaper}>
              YES
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default DI(Test)

const makeQuestion = (arr, n) => {
  n = parseInt(n)
  return arr
    ? arr
        ?.sort(() => Math.random() - 0.5)
        .slice(0, n)
        .map((item, i) => {
          return {
            ...item,
            statusId: i === 0 ? 1 : 2,
            selectedAnswer: -1
          }
        })
    : []
}
