export const api_getPapers={
    success:true,
    data:[
        {
            "id": "papers-3350",
            "paper_name": "2023 shift-I",
            "exam_id": "exams-7603",
            "exam_name": "Demo",
            "no_of_questions": "6",
            "total_time_minutes": "6",
            "total_marks": "12",
            "questions": [
                {
                    "bilingual": false,
                    "paper_id": "papers-3350",
                    "question": {
                        "english": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">Which of the following data types is used to store whole numbers in programming?</span></p>",
                        "hindi": "<p><span style=\"color: rgb(55, 65, 81); background-color: rgb(247, 247, 248);\">प्रोग्रामिंग में पूरी संख्याओं को संग्रहीत करने के लिए निम्नलिखित में से किस डेटा प्रकार का उपयोग किया जाता है?</span></p>"
                    },
                    "options": {
                        "english": [
                            "Float",
                            "String",
                            "Boolean",
                            "Integer"
                        ],
                        "hindi": [
                            "फ़्लोआ",
                            "",
                            "",
                            ""
                        ]
                    },
                    "correct_answer": 3,
                    "imageLink": "",
                    "question_level": "Easy",
                    "explaination": "",
                    "loading": false,
                    "subject": "Computer"
                },
                {
                    "bilingual": false,
                    "paper_id": "papers-3350",
                    "question": {
                        "english": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">What is the result of 3 + 7 * 2 in mathematics?</span></p>",
                        "hindi": "",
                        "explaination": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">According to the order of operations (PEMDAS/BODMAS), multiplication is performed before addition. So, 7 * 2 = 14, and then 3 + 14 = 17.</span></p>"
                    },
                    "options": {
                        "english": [
                            "70",
                            "20",
                            "13",
                            "17"
                        ],
                        "hindi": [
                            "",
                            "",
                            "",
                            ""
                        ]
                    },
                    "correct_answer": 3,
                    "imageLink": "",
                    "question_level": "Easy",
                    "explaination": "",
                    "loading": false,
                    "subject": "Mathematics"
                },
                {
                    "bilingual": false,
                    "paper_id": "papers-3350",
                    "question": {
                        "english": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">In binary representation, what is the decimal value of the binary number 1010?</span></p>",
                        "hindi": "",
                        "explaination": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">The binary number 1010 represents the decimal value 10. Each digit in binary represents a power of 2, from right to left: 1 * 2^3 + 0 * 2^2 + 1 * 2^1 + 0 * 2^0 = 8 + 0 + 2 + 0 = 10.</span></p>"
                    },
                    "options": {
                        "english": [
                            "5",
                            "8",
                            "10",
                            "7"
                        ],
                        "hindi": [
                            "",
                            "",
                            "",
                            ""
                        ]
                    },
                    "correct_answer": 2,
                    "imageLink": "",
                    "question_level": "Easy",
                    "explaination": "",
                    "loading": false,
                    "subject": "Mathematics"
                },
                {
                    "bilingual": false,
                    "paper_id": "papers-3350",
                    "question": {
                        "english": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">Which sorting algorithm has the worst-case time complexity of O(n^2)?</span></p>",
                        "hindi": "",
                        "explaination": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">Bubble Sort has a worst-case time complexity of O(n^2), where 'n' is the number of elements in the array. It repeatedly steps through the list, compares adjacent elements, and swaps them if they are in the wrong order.</span></p>"
                    },
                    "options": {
                        "english": [
                            "Merge Sort",
                            "Quick Sort",
                            "Bubble Sort",
                            "Insertion Sort"
                        ],
                        "hindi": [
                            "",
                            "",
                            "",
                            ""
                        ]
                    },
                    "correct_answer": 2,
                    "imageLink": "",
                    "question_level": "Easy",
                    "explaination": "",
                    "loading": false,
                    "subject": "Computer"
                },
                {
                    "bilingual": false,
                    "paper_id": "papers-3350",
                    "question": {
                        "english": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">If a right triangle has legs of lengths 3 and 4, what is the length of its hypotenuse?</span></p>",
                        "hindi": "",
                        "explaination": "<p><span style=\"background-color: rgb(247, 247, 248); color: rgb(55, 65, 81);\">The length of the hypotenuse of a right triangle can be calculated using the Pythagorean theorem: c^2 = a^2 + b^2, where 'c' is the hypotenuse, and 'a' and 'b' are the lengths of the other two sides. In this case, 3^2 + 4^2 = 9 + 16 = 25, and the square root of 25 is 5, so the length of the hypotenuse is 5</span></p>"
                    },
                    "options": {
                        "english": [
                            "7",
                            "12",
                            "5",
                            "9"
                        ],
                        "hindi": [
                            "",
                            "",
                            "",
                            ""
                        ]
                    },
                    "correct_answer": 2,
                    "imageLink": "",
                    "question_level": "Easy",
                    "explaination": "",
                    "loading": false,
                    "subject": "Mathematics"
                },
                {
                    "bilingual": false,
                    "paper_id": "papers-3350",
                    "question": {
                        "english": "<p>Simplify the following expression:</p><p><strong>(4 + 2) * (8 / 4) + 3 - 5</strong></p>",
                        "hindi": "",
                        "explaination": "<p>Let's break down the expression step by step:</p><ol><li>Inside the parentheses, we have:</li><li>(4 + 2) * (8 / 4) + 3 - 5</li><li>= 6 * 2 + 3 - 5</li><li>Next, perform multiplication and division:</li><li>= 12 + 3 - 5</li><li>Finally, perform addition and subtraction:</li><li>= 15 - 5</li><li>= 10</li></ol><p>So, the simplified value of the expression is 10.</p><p><br></p>"
                    },
                    "options": {
                        "english": [
                            "5",
                            "6",
                            "7",
                            "8"
                        ],
                        "hindi": [
                            "",
                            "",
                            "",
                            ""
                        ]
                    },
                    "correct_answer": 2,
                    "imageLink": "",
                    "question_level": "Easy",
                    "explaination": "",
                    "loading": false,
                    "subject": "Mathematics"
                }
            ],
            "subjects": [
                {
                    "title": "Mathematics"
                },
                {
                    "title": "Computer"
                }
            ],
            "minus_marks": 0.5,
            "created_at": "2023-08-10 13:48:35"
        }
    ]
}