import React from 'react'
import SearchOffIcon from '@mui/icons-material/SearchOff';
const NoSearchFound = () => {
  return (
    <div className='nosearchfound-wrapper'>
      <div className="nosearchfound">
      <span><SearchOffIcon className='animate-charcter' style={{fontSize:"80px",color:'var(--colordeem)'}}/></span>
        <p className='blink'>No Search Found!</p>
      </div>
        </div>
  )
}

export default NoSearchFound