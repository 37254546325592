import React from 'react'
import './HomePage.css'
import SelectionForm from './SelectionForm'
import {useNavigate} from 'react-router-dom'
import Pricing from './Pricing'
import TopBar from './TopBar'
import {DI} from '../../Core/DependencyInjecttion'
const Homepage = (_props) => {
  const navigate = useNavigate()
  return (
    <>
      <main>
        <nav>
          <TopBar />
        </nav>
        <section className="hero">
          <div className="hero_text-wrapper">
            <p>
              Simulate Success, <br />
              <span className="fancy">Conquer Your Exams!</span>
            </p>{' '}
          </div>
          <span className="girlimage-wraper">
            <img className="girl-image1" src="./image/home-feature.png" alt="" />
          </span>
        </section>
        <section className="about_section">
          <SelectionForm />
          <div className="about">
            <p className="about_heading">What it offer</p>
            <p className="about_description">
              Welcome to Our <b>FREE</b> Mock Test Creation and Simulation platform empowers you to
              excel in any exam. Customize mock tests, simulate real exam experiences, and gain
              valuable insights. Join our supportive learner community, embrace excellence, and
              unlock your path to success. Rise to greatness with us today!
            </p>
          </div>
          <button
            className="button-89"
            onClick={() => {
              if (Object.entries(_props.di.session).length) navigate('./panel')
              else _props.di.error('Login please!')
            }}
            style={{minWidth: '200px', margin: '10px', marginBottom: '40px'}}
          >
            STAFF ?
          </button>
        </section>
        <Pricing />
      </main>
    </>
  )
}

export default DI(Homepage)
