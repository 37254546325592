import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import {useNavigate} from 'react-router-dom'

function Copyright() {
  const navigate = useNavigate()
  return (
    <>
      <Typography align="center">
        <a href="mailto:jaikrishnavermaofficial@gmail.com">Click to Share Feedback/Suggestions.</a>
      </Typography>
      <Typography variant="body2" align="center" color="text.secondary">
        {'Copyright © '} [{new Date().getFullYear()}] [{process.env.REACT_APP_NAME}/{' '}
        <Link href="https://jais-portfolio.vercel.app/">jai krishna verma</Link>]. All rights
        reserved. Unauthorized copying or reproduction of this content, in any form, without
        explicit permission is prohibited.
      </Typography>
      <Typography align="center">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            navigate('/')
          }}
        >
          Go to Homepage
        </a>
      </Typography>
    </>
  )
}

export default function Footer() {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          mt: 5,
          flexDirection: 'column'
        }}
      >
        <CssBaseline />
        <Box
          component="footer"
          sx={{
            py: 2,
            px: 1,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]
          }}
        >
          <Container maxWidth="md">
            <Typography variant="body1" align="center" sx={{}}>
              🚧 Attention: This is {process.env.REACT_APP_VERSION} Version Under Development and
              Testing 🚧
            </Typography>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </>
  )
}
