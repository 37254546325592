import React, {useEffect, useState} from 'react'
import './pricing.css'
import {Button} from '@mui/material'
import {homePage} from '../../staticsData/initialData'
const Pricing = () => {
  const [plan, setPlan] = useState([])
  const fetchPlan = () => {
    setPlan(homePage.pricing_plans)
  }
  useEffect(() => {
    fetchPlan()
  }, [])

  if (plan.length === 0) return <></>
  return (
    <div className="pricing_section">
      <div id="pricing-table">
        {plan.map((item) => (
          <div key={item.id} style={{boxShadow: `${item.color} 0px -100px 36px -28px inset`,
          
          }} className={`plan plan3 ${item.cap ? 'cap' : ''}`}>
            {item.cap && <span className="price_badge">{item.cap}</span>}
            <div class="header" style={{backgroundColor:item.color}}>{item.plan_name}</div>
            <div class="price">₹{item.price}</div>
            <div class="monthly">{item.duration}</div>
            <ul>
              {item.features.map((list) => (
                <li>
                  &#x2705; {list.split('*').map((item, index) => {
                    if (index % 2) return <b>{item.toUpperCase()}</b>
                    else return item
                  })}
                </li>
              ))}
            </ul>
            <Button fullWidth variant='outlined'  style={{background: 'var(--color2)', color: 'var(--color1light)'}}>
             { item.buttonName.toUpperCase()}
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Pricing
