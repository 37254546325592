import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import Grid from '@mui/material/Unstable_Grid2'
import {MyContext} from '../../staticsData/MyContext'
import {useSnackbar} from 'notistack'
import {HeadingFormat} from './panelroutes'
import AddQuestion from './AddQuestion'
import {createdAt, idGenerate, noRef} from '../utility/GlobalFunctons'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
import Loading from '../Fallback/Loading'
import ButtonLoader from '../Common/ButtonLoader'
function CustomTabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {fontWeight: 'bold'},
    iconPosition: 'start'
  }
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Create Paper" {...a11yProps(0)} icon={<PlaylistAddCheckIcon />} />
          <Tab label="Add Questions" {...a11yProps(1)} icon={<StickyNote2Icon />} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CreatePaper />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AddQuestion />
      </CustomTabPanel>
    </Box>
  )
}
const initialPaperState = {
  paper_name: '',
  exam_id: '',
  no_of_questions: '',
  total_time_minutes: '60',
  total_marks: '100',
  subjects: [],
  minus_marks: '0.00'
}
const CreatePaper = DI((_props) => {
  const [data, setData] = React.useState({
    papers: [],
    exams: [],
    loading: true
  })
  const {papers, exams, loading} = data
  const [state, setState] = React.useState({
    ...noRef(initialPaperState)
  })
  const [btnLoader, setBtnLoader] = React.useState(false)
  const getData = () => {
    setData((prev) => {
      return {
        ...prev,
        loading: true
      }
    })
    _props.di
      .GET(urlFetchCalls.get.get_exams)
      .then((res) => {
        if (res.success) {
          setData((prev) => {
            return {
              ...prev,
              exams: res?.data ?? []
            }
          })
          _props.di.GET(urlFetchCalls.get.get_papers).then((res) => {
            if (res.success) {
              setData((prev) => {
                return {
                  ...prev,
                  papers: res?.data ?? []
                }
              })
            } else {
              _props.di.error(res?.message)
            }
          })
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() =>
        setData((prev) => {
          return {
            ...prev,
            loading: false
          }
        })
      )
  }
  React.useEffect(() => {
    getData()
  }, [])
  const createPaper = (e) => {
    e.preventDefault()
    if (state.subjects.length === 0) {
      e.target.querySelector('[componentname=subjectTags]').style.border = '1px solid red'
      e.target.querySelector('[componentname=subjectTags]').style.borderRadius = '4px'
    } else {
      setBtnLoader(true)
      e.target.querySelector('[componentname=subjectTags]').style.border = 'none'
      const {
        paper_name,
        exam_id,
        no_of_questions,
        total_time_minutes,
        total_marks,
        subjects,
        minus_marks
      } = state
      const exam_name = exams.find((item) => item.id === exam_id).exam_name
      _props.di
        .POST(urlFetchCalls.post.create_paper, {
          paper_name,
          exam_id,
          exam_name,
          no_of_questions,
          total_time_minutes,
          total_marks,
          subjects:JSON.stringify({subjects}),
          minus_marks
        })
        .then((res) => {
          if (res.success) {
            setState({...noRef(initialPaperState)})
            _props.di.success(res?.message)
          } else {
            _props.di.error(res?.message)
          }
        })
        .finally(() => setBtnLoader(false))
    }
  }
  if (loading) return <Loading />;
  return (
    <form onSubmit={createPaper}>
      <Grid container sx={{marginTop: '10px'}} spacing={2}>
        <Grid xs={12}>
          <TextField
            label="Paper Name"
            id="outlined-size-small"
            defaultValue=""
            placeholder="UPSC-pre 2021"
            size="small"
            fullWidth
            value={state.paper_name}
            required
            onChange={(e) => setState({...state, paper_name: e.target.value})}
          />
        </Grid>
        <Grid xs={12}>
          <FormControl sx={{minWidth: 120}} fullWidth size="small">
            <InputLabel id="demo-select-small-label">Choose a Exam</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={state.exam_id}
              onChange={(e) => setState({...state, exam_id: e.target.value})}
              label="Choose a Exam"
              placeholder="Centra Gov."
              required
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {exams.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {HeadingFormat(option.exam_name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <TextField
            label="No of Questions"
            id="outlined-size-small"
            defaultValue="0"
            size="small"
            fullWidth
            type="number"
            required
            value={state.no_of_questions}
            onChange={(e) => setState({...state, no_of_questions: e.target.value})}
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            label="Total Time given in minutes"
            id="outlined-size-small"
            defaultValue="0"
            size="small"
            fullWidth
            type="number"
            value={state.total_time_minutes}
            onChange={(e) => setState({...state, total_time_minutes: e.target.value})}
            required
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            label="Total marks"
            id="outlined-size-small"
            defaultValue="0"
            size="small"
            fullWidth
            type="number"
            value={state.total_marks}
            onChange={(e) => setState({...state, total_marks: e.target.value})}
            required
          />
        </Grid>
        <Grid xs={6}>
          <Autocomplete
            multiple
            required
            size="small"
            componentName="subjectTags"
            id="multiple-subjects-tags"
            options={subjects}
            getOptionLabel={(option) => option.title}
            defaultValue={[]}
            renderInput={(params) => (
              <TextField
                name="subjects_tags"
                {...params}
                label="Subjects"
                placeholder="Select multiple"
              />
            )}
            onChange={(event, val) => setState({...state, subjects: val})}
          />
        </Grid>
        <Grid xs={12} md={3}>
          <TextField
            label="Minus Marking"
            id="outlined-size-small"
            defaultValue="0.00"
            size="small"
            fullWidth
            type="number"
            value={state.minus_marks}
            onChange={(e) =>
              setState({...state, minus_marks: Math.abs(parseFloat(e.target.value))})
            }
            required
          />
          <small style={{color: 'grey'}}>If there is no minus marking leave it with 0 value</small>
        </Grid>
        <Grid xs={12}>

          <ButtonLoader
                variant="contained" loading={btnLoader} type="submit" fullWidth color="success"
              >
              Create Paper
              </ButtonLoader>
        </Grid>
      </Grid>
    </form>
  )
})
const subjects = [
  {title: 'Mathematics'},
  {title: 'Physics'},
  {title: 'Chemistry'},
  {title: 'Computer'},
  {title: 'Resoning'},
  {title: 'English'},
  {title: 'Hindi'},
  {title: 'History'},
  {title: 'Geography'},
  {title: 'Current Affairs'},
  {title: 'Other'}
]
