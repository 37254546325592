import React from "react";
import "./Fallback.css";
import CircularProgress from "@mui/material/CircularProgress";
const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "350px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        flexWrap: "nowrap",
        justifyContent: "center",
        borderRadius: "5px",
        color: "var(--color2)",
      }}
    >
      <div>
        <CircularProgress />
      </div>
      <h2 className="animate-charcter">Loading...</h2>
    </div>
  );
};

export default Loading;
