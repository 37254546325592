import React, {useEffect, useState, useContext} from 'react'
import './Result.css'
import {MyContext} from '../../staticsData/MyContext'
import {Navigate, useLocation, useParams, useSearchParams} from 'react-router-dom'
import {Question} from '../Panel/ShowQuestion'
import DescriptionIcon from '@mui/icons-material/Description'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Doughnut} from 'react-chartjs-2'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material'
import {HeadingFormat} from '../Panel/panelroutes'
import Footer from '../utility/Footer'

const Result = () => {
  const location = useLocation()
  let {submission, paper, user} = location.state
  const {userId, paperId} = useParams()
  const {centralState} = useContext(MyContext)
  const [show, setShow] = useState(false)
  if (!paperId || !userId || !paper || !user) return <Navigate to={'../'} />

  const answersSheet = submission.questions
  const color = pickAnyColor(paper.subjects.length)

  const generateNo = () => {
    return paper.subjects.map((sub) => {
      return answersSheet.filter((item) => {
        return item.subject == sub.title
      }).length
    })
  }

  const data = {
    labels: paper.subjects.map((item) => item.title),
    datasets: [
      {
        label: 'Questions',
        data: generateNo(),
        backgroundColor: color,
        borderColor: Array(color.length).fill('White'),
        borderWidth: 1
      }
    ]
  }

  const result = finalResult(paper, answersSheet)
  return (
    <main className="result_main">
      <div
        style={{
          display: 'flex',
          width: '85vw',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h1>{HeadingFormat(user.user_name)}</h1>
        <button
          className="showCopy"
          onClick={() => setShow(!show)}
          style={{display: 'flex', alignItems: 'center', gap: '10px'}}
        >
          {show ? (
            <DisabledByDefaultIcon sx={{fontSize: 20}} />
          ) : (
            <DescriptionIcon sx={{fontSize: 20}} />
          )}
          {`${show ? 'Close Copy' : 'Show Copy'}`}
        </button>
      </div>
      <i>
        <h2 style={{margin: '10px 0px'}} className="result_head_desc">
          Result of ( {paper.paper_name} )
        </h2>
      </i>

      {!show ? (
        <div className="result_calculation">
          <Doughnut data={data} />
          <div
            className="result_table"
            style={{
              margin: '20px 0 0 0',
              width: 'inherit',
              overflowX: 'scroll'
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {result?.data?.[0] &&
                      Object.keys(result.data[0]).map((item) => (
                        <TableCell>{HeadingFormat(item)}</TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.data?.map((row) => (
                    <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      {Object.values(row).map((item) => (
                        <TableCell align="left">{HeadingFormat(item)}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow sx={{borderTop: '2px solid rgb(21, 87, 161)'}}>
                    <TableCell align="left">
                      <p className="result_details">Total Score</p>
                    </TableCell>
                    <TableCell align="left">
                      <p className="result_details">
                        {result.total_marks}/{paper.total_marks}{' '}
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <p>Grade</p>
                    </TableCell>
                    <TableCell align="left">
                      <p>{grade((result.total_marks * 100) / paper.total_marks)} </p>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : (
        <>
          <h2
            style={{
              width: '85vw',
              color: 'rgb(21, 87, 161)',
              marginTop: '15px'
            }}
          >
            Answer Copy :
          </h2>
          <div className="result_copy">
            {answersSheet?.map((item, index) => (
              <div key={'answercopy' + index} className="result_copy_paper">
                <h3>Question no. {index + 1}</h3>
                <Question question={item} />
              </div>
            ))}
          </div>
        </>
      )}
      <Footer />
    </main>
  )
}

export default Result
ChartJS.register(ArcElement, Tooltip, Legend)

const pickAnyColor = (num) => {
  const colors = [
    '#4a4e4d',
    '#0e9aa7',
    '#3da4ab',
    '#f6cd61',
    '#fe8a71',
    '#63ace5',
    '#ee4035',
    '#1e1f26',
    '#aaaaaa'
  ]
  const generated = colors.sort(() => 0.5 - Math.random()).slice(0, num)
  return generated
}
const finalResult = (paper, answersSheet) => {
  const {total_marks, no_of_questions} = paper
  let totalMarking = 0
  const obj = answersSheet.map((item, index) => {
    if (item.statusId == 4) item.selectedAnswer = -1
    const marking =
      item.correct_answer == item.selectedAnswer
        ? parseInt(total_marks) / parseInt(no_of_questions)
        : item.selectedAnswer === -1
        ? 0
        : -paper?.minus_marks

    totalMarking = totalMarking + marking
    const obj = {}
    obj['Q.No.'] = index + 1
    obj['correct_answer'] = item.options.english[item.correct_answer]
    obj['answer_you_choose'] =
      item.selectedAnswer == '-1' ? 'Not Answered' : item.options.english[item.selectedAnswer]
    obj['marking'] = marking
    return obj
  })
  return {data: obj, total_marks: totalMarking}
}

const grade = (num) => {
  if (num > 90) return 'A++'
  if (num > 80) return 'A+'
  if (num > 70) return 'A'
  if (num > 60) return 'B+'
  if (num > 50) return 'B'
  else return 'C (Need More Practice)'
}
