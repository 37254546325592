import {createContext, useState, React, useEffect, useCallback} from 'react'
import {initialState} from './initialData'
import {noRef} from '../components/utility/GlobalFunctons'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'
export const MyContext = createContext()
const localname = 'live_exams_temp251_v1'

export const ContextWraper = ({children}) => {
  const [centralState, setCentralState] = useState({
    session: {},
    loading: true
  })

  /**
   * responsible for local storage
   */
  useEffect(() => {
    const data = localStorage.getItem(localname)
    if (data) setCentralState({...JSON.parse(data), loading: false})
    else setCentralState({...centralState, loading: false})
  }, [])

  useEffect(() => {
    if (JSON.stringify(initialState) !== JSON.stringify(centralState))
      localStorage.setItem(localname, JSON.stringify(centralState))
  }, [centralState])

  const provide = {
    setCentralState,
    centralState
  }
  if (centralState.loading)
    return (
      <Backdrop sx={{color: '#fff',background:'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(45,147,253,1) 100%);', zIndex: (theme) => theme.zIndex.drawer + 1}} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  return <MyContext.Provider value={provide}>{children}</MyContext.Provider>
}
ContextWraper.propTypes = {
  children: PropTypes.node
}
