import {Box, Button, Skeleton} from '@mui/material'
import React, {useEffect, useMemo, useState} from 'react'
import AddIcon from '@mui/icons-material/Add'
import {useNavigate, useSearchParams} from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import {visuallyHidden} from '@mui/utils'
import {HeadingFormat} from './panelroutes'
import FallbackList from '../Fallback/FallbackList'
import DescriptionIcon from '@mui/icons-material/Description'
import {deleteById, noRef, timeformat} from '../utility/GlobalFunctons'
import {EnhancedTableToolbar, getComparator, stableSort} from '../utility/CommonComp'
import NoSearchFound from '../Fallback/NoSearchFound'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
import SkeltonTable from '../../Skeltons/SkeltonTable'
import Loading from '../Fallback/Loading'
const tableColumns = [
  'id',
  'paper_name',
  'exam_name',
  'no_of_questions',
  'paper_name',
  'total_time_minutes',
  'total_marks',
  'minus_marks',
  'subjects'
]
const PaperList = (_props) => {
  const navigate = useNavigate()
  const [papers, setPapers] = React.useState([])
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const examId = searchParams.get('examId')
  const [searchInput, setSearchInput] = useState(examId ? examId : '')
  const debounceref = React.useRef(null)
  React.useEffect(() => {
    if (debounceref.current) clearInterval(debounceref.current)
    debounceref.current = setTimeout(() => {
      getPapers()
    }, 200)
    return () => clearInterval(debounceref.current)
  }, [searchInput])
  const getPapers = () => {
    setLoading(true)
    _props.di
      .GET(urlFetchCalls.get.get_papers, {
        search: searchInput
      })
      .then((res) => {
        if (res.success) {
          setPapers(res?.data ?? [])
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() => setLoading(false))
  }
  const tableProps = useMemo(() => {
    return {
      papers: papers.map((paper) => {
        const row = {}
        Object.entries(paper).forEach(([key, val]) => {
          if (tableColumns.includes(key)) row[key] = val
        })
        return row
      }),
      tableLoading: loading,
      searchInput,
      setSearchInput
    }
  }, [papers, searchInput, loading])
  return (
    <Box sx={{width: '100%', maxWidth: '90vw'}}>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        style={{backgroundColor: 'var(--color2)'}}
        onClick={() => navigate('../create_paper')}
      >
        Create Paper
      </Button>
      <div
        style={{
          margin: '20px 0 0 0',
          width: 'inherit'
        }}
      >
        {/* {loading && !searchInput ? (
            <Loading />
          ) : (
            <>
              {isPapersExist ?  */}
        <EnhancedTable {...tableProps} />
        {/* : <FallbackList title="paper" />
            </>
          )} */}
      </div>
    </Box>
  )
}

export default DI(PaperList)

function EnhancedTable(props) {
  const {searchInput, papers, tableLoading, setSearchInput} = props
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const navigate = useNavigate()
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = papers.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const deleteSelected = () => {
    alert('PaperList delete api write')
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - papers.length) : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort(papers, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, papers.length]
  )
  const tableHeadings = useMemo(() => {
    return tableColumns.map((key) => {
      return {
        id: key,
        numeric: false,
        disablePadding: true,
        label: HeadingFormat(key)
      }
    })
  }, [])

  return (
    <Box sx={{maxWidth: '100%'}}>
      <Paper sx={{width: '100%', mb: 1}}>
        <EnhancedTableToolbar
          deleteSelected={deleteSelected}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size={'small'} loading={true}>
            <EnhancedTableHead
              headCells={tableHeadings}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={papers.length}
              papers={papers}
            />
            <TableBody>
              {tableLoading ? (
                Array(rowsPerPage).fill(
                  <TableRow>
                    {Array(11).fill(
                      <TableCell>
                        <Skeleton variant="rounded" fullWidth height={30} />
                      </TableCell>
                    )}
                  </TableRow>
                )
              ) : (
                <>
                  {visibleRows.length === 0 ? (
                    <TableRow>
                      {searchInput ? (
                        <div
                          style={{
                            minHeight: '200px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <NoSearchFound />
                        </div>
                      ) : (
                        <TableCell colSpan={'20'}>
                          <FallbackList title="exam" />
                        </TableCell>
                      )}
                    </TableRow>
                  ) : (
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{cursor: 'pointer'}}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => handleClick(event, row.id)}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                            />
                          </TableCell>

                          {tableColumns.map((key) => {
                            const val = row[key]
                            return key === 'created_at' ? (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="5px"
                                sx={{fontSize: '15.5px'}}
                              >
                                {timeformat(val)}
                              </TableCell>
                            ) : (
                              <TableCell sx={{fontSize: '15.5px'}} padding="5px" align="left">
                                {HeadingFormat(val)}
                              </TableCell>
                            )
                          })}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="5px"
                            sx={{fontSize: '15.5px'}}
                          >
                            <Button
                              startIcon={<DescriptionIcon />}
                              variant="contained"
                              sx={{backgroundColor: 'var(--color1)'}}
                              onClick={() => navigate(`../view_questions?paperId=${row.id}`)}
                              size="small"
                            >
                              Open
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )}
                </>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 40 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {papers.length > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={papers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  )
}

function EnhancedTableHead(props) {
  const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells} = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{background: 'var(--color3)'}}>
          <Checkbox
            color="primary"
            indeterminate={numSelected && numSelected === rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all papers'
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'5px'}
            sx={{background: 'var(--color3)', fontWeight: 'bold', fontSize: '16px'}}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          padding="checkbox"
          sx={{background: 'var(--color3)', fontWeight: 'bold', fontSize: '16px'}}
        >
          View
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
