import React, {useState} from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {MyContext} from '../../staticsData/MyContext'
import {useSnackbar} from 'notistack'
import {HeadingFormat} from './panelroutes'
import AddIcon from '@mui/icons-material/Add'
import Loading from '../Fallback/Loading'
import {noRef} from '../utility/GlobalFunctons'
import ReactQuill from 'react-quill'
import ImageUpload from '../utility/ImageUpload'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
const initialQuestion = {
  bilingual: false,
  paper_id: '',
  subject:'',
  question: {
    english: '',
    hindi: ''
  },
  options: {
    english: ['', '', '', ''],
    hindi: ['', '', '', ''],
    images: ['', '', '', ''],
  },
  correct_answer: '',
  imageLink: '',
  question_level: 'Easy',
  explaination: '',
  loading: false
}
const AddQuestion = (_props) => {
  const {
    // centralState: {papers = []},
    setCentralState
  } = React.useContext(MyContext)
  const [state, setState] = React.useState(noRef(initialQuestion))
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [papers, setPapers] = useState([])
  const [selectedPaper, setSelectedPaper] = useState(null)
  const [btnLoader,setBtnLoader]=useState(false)
const createQuestion=(e)=>{
  e.preventDefault()

    setBtnLoader(true)
    // e.target.querySelector('[componentname=subjectTags]').style.border = 'none'
    const {
      bilingual,
      paper_id,
      question,
      options,
      correct_answer,
      imageLink,
      subject,
      question_level,
      explaination,
    } = state
    _props.di
      .POST(urlFetchCalls.post.create_question, {
        bilingual,
        paper_id,
        question:question,
        options:options,
        correct_answer,
        imageLink,
        subject,
        question_level,
        explaination,
      })
      .then((res) => {
        if (res.success) {
          setState({...noRef(initialQuestion)})
          _props.di.success(res?.message)
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() => setBtnLoader(false))
  
}

  React.useEffect(() => {
    getPapers()
  }, [])
  const getPapers = () => {
    setLoading(true)
    _props.di
      .GET(urlFetchCalls.get.get_papers)
      .then((res) => {
        if (res.success) {
          setPapers(res?.data ?? [])
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  // https://api.mymemory.translated.net/get?q=text&langpair=en|hi
  /**
   *
   * @param {*} text string to save
   * @param {*} optionIndex if for option
   */
  const translator = (text, optionIndex = -1) => {
    setState((prev) => {
      if (optionIndex == -1) {
        prev.question.english = text
      } else {
        prev.options.english[optionIndex] = text
      }
      return {...state}
    })
    if (state.bilingual)
      fetch(`https://api.mymemory.translated.net/get?q=${text}&langpair=en|hi`)
        .then((res) => res.json())
        .then((res) => {
          if (res.responseStatus == 200)
            setState((prev) => {
              if (optionIndex == -1) {
                prev.question.hindi = res.responseData.translatedText
              } else {
                prev.options.hindi[optionIndex] = res.responseData.translatedText
              }
              return {...state}
            })
        })
  }
  const selectPaper = (e) => {
    const id = e.target.value
    const selectedPaper = papers.find((el) => el.id == id)
    setSelectedPaper({
      ...selectedPaper,
      subjects: JSON.parse(selectedPaper?.subjects)?.subjects ?? null
    })
    setState({...state, paper_id: id})
  }
  const setQuestionImage=(url,i)=>{
    setState(prev=>{
      return {...prev,imageLink:url}
    })
  }
  const setOptionImage=(url,i)=>{
    setState(prev=>{
      prev.options.images[i]=url
      return {...prev}
    })
  }
  if (loading) return <Loading />

  return (
      <form onSubmit={createQuestion}>
        <h3
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            color: 'var(--color2)'
          }}
        >
          <AddIcon /> Add Question :
        </h3>
        <Grid container sx={{marginTop: '10px'}} spacing={2}>
          <Grid xs={12}>
            {state.exam_name && (
              <TextField
                id="outlined-size-small"
                disabled
                placeholder="UPSC-pre 2021"
                size="small"
                fullWidth
                value={state.exam_name}
                required
              />
            )}
          </Grid>
          <Grid xs={12}>
            <FormControl sx={{minWidth: 120}} fullWidth size="small">
              <InputLabel id="demo-select-small-label">Choose a Paper</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={state.paper_id}
                onChange={selectPaper}
                label="Choose a Exam"
                placeholder="Centra Gov."
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {papers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {HeadingFormat(
                     option?.paper_name + '  #'+HeadingFormat(option?.exam_name)
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={state.bilingual ? 6 : 12} sx={{zIndex: '1000'}}>
            <InputLabel sx={{fontWeight: '600'}} id="demo-select-small-label">
              Write Question in English
            </InputLabel>

            <ReactQuill
              theme="snow"
              value={state.question.english}
              onChange={(val) => translator(val)}
              placeholder="Write Question Here..."
            />
            <ImageUpload index={0} setImage={setQuestionImage}/>
          </Grid>
          {state.bilingual && (
            <Grid xs={12} md={6} sx={{zIndex: '1000'}}>
              <InputLabel sx={{fontWeight: '600'}} id="demo-select-small-label">
                Write Question in Secondary Language
              </InputLabel>
              <ReactQuill
                theme="snow"
                value={state.question.hindi}
                onChange={(e) => {
                  state.question.hindi = e
                  setState({...state})
                }}
                placeholder="यहाँ प्रश्न लिखें..."
              />
            </Grid>
          )}
          {Array(4)
            .fill('')
            .map((op, i) => {
              return (
                <>
                  {' '}
                  <Grid key={JSON.stringify(papers)} xs={12} md={6}>
                    <TextField
                      aria-label="Demo input"
                      multiline
                      placeholder="Type something…"
                      label={`English Option ${i + 1}`}
                      id="outlined-size-small"
                      size="small"
                      value={state.options.english[i]}
                      onChange={(e) => translator(e.target.value, i)}
                      fullWidth
                      required
                    />
                    <ImageUpload index={i} setImage={setOptionImage}/>
                  </Grid>
                  {state.bilingual && (
                    <Grid key={JSON.stringify(papers) + 'hindi'} xs={12} md={6}>
                      <TextField
                        aria-label="Demo input"
                        multiline
                        placeholder="Type something…"
                        label={`Seconday language Option ${i + 1}`}
                        id="outlined-size-small"
                        size="small"
                        value={state.options.hindi[i]}
                        onChange={(e) => {
                          state.options.hindi[i] = e.target.value
                          setState({...state})
                        }}
                        required
                        fullWidth
                      />
                    </Grid>
                  )}
                </>
              )
            })}
          <Grid xs={12}>
            <FormControl sx={{minWidth: 120}} fullWidth size="small">
              <InputLabel id="demo-select-small-label">Choose Correct Answer</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={state.correct_answer}
                onChange={(e) => {
                  setState({...state, correct_answer: e.target.value})
                }}
                label="Choose Correct Answer"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {state.options.english.map((option, index) => (
                  <MenuItem key={option} value={index}>
                   Option {index+1} : {HeadingFormat(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} x={{zIndex: '1000'}}>
            <InputLabel id="demo-select-small-label">Write Answer Explaination</InputLabel>

            <ReactQuill
              theme="snow"
              value={state.explaination}
              onChange={(val) =>
                setState({...state, explaination: val})
              }
              placeholder="Answer Explaination Here..."
            />
          </Grid>
          <Grid xs={6}>
            <FormControl sx={{minWidth: 120}} fullWidth size="small">
              <InputLabel id="demo-select-small-label">Question level</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={state.question_level}
                onChange={(e) => setState({...state, question_level: e.target.value})}
                label="Question Level"
                placeholder="Centra Gov."
                required
              >
                {['Easy', 'Medium', 'Hard'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {HeadingFormat(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6}>
            <FormControl sx={{minWidth: 120}} fullWidth size="small">
              <InputLabel id="demo-select-small-label">Belong to subject</InputLabel>
              <Select
                labelId="demo-select-small-label"
                disabled={!selectedPaper}
                id="demo-select-small"
                value={state.subject}
                onChange={(e) => {
                  setState({...state, subject: e.target.value})
                }}
                label="Choose Correct Answer"
                required
              >
                {selectedPaper?.subjects?.map((option) => (
                  <MenuItem key={option.title} value={option.title}>
                    {HeadingFormat(option.title)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.bilingual}
                  onChange={(e) => setState({...state, bilingual: e.target.checked})}
                  name="antoine"
                />
              }
              label="Bilingual Question"
            />
          </Grid>

          <Grid xs={12}>
            <Button variant="contained" type="submit" fullWidth color="success">
              Create Question
            </Button>
          </Grid>
        </Grid>
      </form>
  )
}

export default DI(AddQuestion)
