import {Button, CircularProgress} from '@mui/material'
import React from 'react'

const ButtonLoader = (props) => {
  if (props?.loading === true)
    return (
      <Button {...props}  disabled={true} startIcon={ <CircularProgress size={26} color="inherit" />}>
       {props.children}
      </Button>
    )
  else return <Button {...props}>{props?.children}</Button>
}

export default ButtonLoader
