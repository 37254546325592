import {Outlet, RouterProvider, createBrowserRouter} from 'react-router-dom'
import './App.css'
import {ContextWraper} from './staticsData/MyContext'
import Test from './components/Portal/Test'
import Panel from './components/Panel/Panel'
import {panelChildren} from './components/Panel/panelroutes.js'
import {SnackbarProvider} from 'notistack'
import Homepage from './components/Home/Homepage'
import Result from './components/Portal/Result'
import SignIn from './components/Auth/SignIn'
import SignUp from './components/Auth/SignUp'
import ErrorPage from './Core/ErrorPage.jsx'
import Footer from './components/utility/Footer.jsx'
const routesArray = [
  {
    path: '/*',
    element: (
      <>
        <Outlet />
        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'register',
        element: <SignUp />
      },
      {
        path: 'login',
        element: <SignIn />
      },
      {
        path: 'test/:userId/:paperId',
        element: <Test />
      },
      {
        path: 'result/:userId/:paperId',
        element: <Result />
      },
      {
        path: '*',
        element: <Homepage />
      }
    ]
  },
  {
    path: '/panel/*',
    element: <Panel />,
    children: panelChildren.map((item) => {
      return {path: item.path, element: item.element}
    })
  }
]

function App() {
  const router = createBrowserRouter(routesArray)
  return (
    <SnackbarProvider>
      <ContextWraper>
        <RouterProvider router={router} />
      </ContextWraper>
    </SnackbarProvider>
  )
}

export default App
