export const urlFetchCalls = {
  get: {
    get_exams: 'get_exams',
    get_papers: 'get_papers',
    get_questions: 'get_questions'
  },
  post: {
    create_exam: 'create_exam',
    create_paper: 'create_paper',
    create_question: 'create_question',
    login: 'login',
    signup: 'register',
    uploadImage: 'upload-image',
    uploadBase64:'uploadBase64'
  }
}
