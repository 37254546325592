import * as React from 'react'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import {Button, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {MyContext} from '../../staticsData/MyContext'
import {useSnackbar} from 'notistack'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import {visuallyHidden} from '@mui/utils'
import {HeadingFormat} from './panelroutes'
import FallbackList from '../Fallback/FallbackList'
import {
  createdAt,
  deleteById,
  idGenerate,
  isValidTimestamp,
  noRef,
  timeformat
} from '../utility/GlobalFunctons'
import {useNavigate} from 'react-router-dom'
import {EnhancedTableToolbar, getComparator, stableSort} from '../utility/CommonComp'
import NoSearchFound from '../Fallback/NoSearchFound'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
import ButtonLoader from '../Common/ButtonLoader'
import SkeltonTable from '../../Skeltons/SkeltonTable'
import Loading from '../Fallback/Loading'

function BasicTabs(_props) {
  const {centralState, setCentralState} = React.useContext(MyContext)
  const [loading, setLoading] = React.useState(true)
  const debounceref = React.useRef(null)
  const [state, setState] = React.useState({
    exam_name: '',
    exam_type: ''
  })
  const [searchInput, setSearchInput] = React.useState('')
  const [exams, setExams] = React.useState([])
  const addExam = (e) => {
    e.preventDefault()
    setLoading(true)
    const {exam_name, exam_type} = state
    _props.di
      .POST(urlFetchCalls.post.create_exam, {
        exam_name,
        exam_type
      })
      .then((res) => {
        if (res.success) {
          setState({...state, exam_name: '', exam_type: ''})
          _props.di.success(res?.message)
          getExam()
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() => setLoading(false))
  }
  React.useEffect(() => {
    if (debounceref.current) clearInterval(debounceref.current)
    debounceref.current = setTimeout(() => {
      getExam()
    }, 200)
    return () => clearInterval(debounceref.current)
  }, [searchInput])
  const getExam = () => {
    setLoading(true)
    _props.di
      .GET(urlFetchCalls.get.get_exams, {
        search: searchInput
      })
      .then((res) => {
        if (res.success) {
          setExams(res?.data ?? [])
        } else {
          _props.di.error(res?.message)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Box sx={{width: '100%', maxWidth: '90vw'}}>
      <form onSubmit={addExam}>
        <h3 style={{color: 'var(--color2)', marginBottom: '10px'}}>Add Exam :</h3>
        <Grid container spacing={2}>
          <Grid xs={12} md={4}>
            <TextField
              label="Exam Name"
              id="outlined-size-small"
              defaultValue=""
              size="small"
              fullWidth
              required
              value={state.exam_name}
              onChange={(val) => {
                setState({...state, exam_name: val.target.value})
              }}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <FormControl sx={{minWidth: 120}} fullWidth size="small">
              <InputLabel id="demo-select-small-label">Exam Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={age}
                label="Exam Type"
                required
                // onChange={handleChange}
                value={state.exam_type}
                onChange={(val) => setState({...state, exam_type: val.target.value})}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {['Central Gov.', 'State Gov.', 'Others'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={4}>
            {/* <Button
                startIcon={<AddIcon />}
                style={{background: 'var(--color2)'}}
                type="submit"
                variant="contained"
              > */}
            <ButtonLoader
              loading={loading}
              startIcon={<AddIcon />}
              style={{background: 'var(--color2)', color: 'white'}}
              type="submit"
              variant="contained"
            >
              Add Exam
            </ButtonLoader>
            {/* Add Exam
              </Button> */}
          </Grid>
        </Grid>
      </form>
      <div
        style={{
          margin: '20px 0 0 0',
          maxWidth: 'inherit'
        }}
      >
        {/* {loading && !searchInput ? (
            <Loading />
          ) : (
            <>
              {exams.length > 0 || searchInput ? ( */}
        <EnhancedTable
          loading={loading}
          exams={exams}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        {/* ) : (
                <FallbackList title="exam" />
              )}
            </>
          )} */}
      </div>
    </Box>
  )
}
export default DI(BasicTabs)
function EnhancedTableHead(props) {
  const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  const {exams} = props

  // to console headcell for copy
  // const headCells = Object.entries(exams[0]).map(([key, val]) => {
  //   return {
  //     id: key,
  //     numeric: false,
  //     disablePadding: true,
  //     label: HeadingFormat(key)
  //   }
  // })
  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'Id'
    },
    {
      id: 'exam_name',
      numeric: false,
      disablePadding: true,
      label: 'Exam Name'
    },
    {
      id: 'exam_type',
      numeric: false,
      disablePadding: true,
      label: 'Exam Type'
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: true,
      label: 'Created At'
    }
  ]

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{background: 'var(--color3)'}}>
          <Checkbox
            color="primary"
            indeterminate={numSelected && numSelected === rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts'
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'5px'}
            sx={{background: 'var(--color3)', fontWeight: 'bold', fontSize: '16px'}}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          padding="checkbox"
          sx={{background: 'var(--color3)', fontWeight: 'bold', fontSize: '16px'}}
        >
          Papers
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

function EnhancedTable({exams, searchInput, setSearchInput, loading}) {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const navigate = useNavigate()
  React.useEffect(() => {
    setRowsPerPage(5)
  }, [searchInput])
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = exams.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - exams.length) : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort(exams, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, exams.length]
  )
  const deleteSelected = () => {
    alert('create delete api')
    // setCentralState((prev) => {
    //   prev.exams = deleteById(selected, prev.exams)
    //   return noRef(prev)
    // })
    setSelected([])
  }

  return (
    <Box sx={{width: '100%'}}>
      <Paper sx={{width: '100%', mb: 1}}>
        <EnhancedTableToolbar
          deleteSelected={deleteSelected}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          numSelected={selected.length}
        />
        <TableContainer style={{minHeight: `${300}px`}}>
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size={'small'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={exams.length}
              exams={exams}
            />
            <TableBody>
              {loading ? (
                Array(rowsPerPage).fill(
                  <TableRow>
                    {Array(6).fill(
                      <TableCell>
                        <Skeleton variant="rounded" fullWidth height={30} />
                      </TableCell>
                    )}
                  </TableRow>
                )
              ) : (
                <>
                  {visibleRows.length === 0 ? (
                    <TableRow>
                      {searchInput ? (
                        <div
                          style={{
                            minHeight: '200px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <NoSearchFound />
                        </div>
                      ) : (
                        <TableCell colSpan={'20'}>
                          <FallbackList title="exam" />
                        </TableCell>
                      )}
                    </TableRow>
                  ) : (
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{cursor: 'pointer'}}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => handleClick(event, row.id)}
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                            />
                          </TableCell>

                          {Object.entries(row).map(([key, val]) => {
                            return key === 'created_at' || key === 'updated_at' ? (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="5px"
                                sx={{fontSize: '15.5px'}}
                              >
                                {isValidTimestamp(val) ? timeformat(val) : HeadingFormat(val)}
                              </TableCell>
                            ) : (
                              <TableCell sx={{fontSize: '15.5px'}} padding="5px" align="left">
                                {HeadingFormat(val)}
                              </TableCell>
                            )
                          })}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="5px"
                            sx={{fontSize: '15.5px'}}
                          >
                            <Button
                              startIcon={<DocumentScannerIcon />}
                              variant="contained"
                              sx={{backgroundColor: 'var(--color1)'}}
                              onClick={() => navigate(`../papers?examId=${row.id}`)}
                              size="small"
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )}
                </>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 40 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {exams.length > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={exams.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  )
}
