import React, {useEffect} from 'react'
import styled from 'styled-components'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import {useState} from 'react'
import ReactFileReader from 'react-file-reader'
import {Button, CircularProgress} from '@mui/material'
import {dataURItoBlob} from './GlobalFunctons'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  .circle {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }
    &:hover {
      background: blue;
    }
  }
`

function ImageUpload(_props) {
  const {setImage, index} = _props
  const session = _props?.di?.state?.session
  const [url, setUrl] = useState(_props?.url ?? '')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setImage(url, index)
  }, [url])
  const handleFiles = (files) => {
    setLoading(true)
    _props.di
      .POST(urlFetchCalls.post.uploadBase64, {
        image: files.base64,
        filename: files.fileList[0].name,
        uid: session?.id?.toString()
      })
      .then((res) => {
        if (res.success) {
          setUrl(res.url)
        } else {
          setUrl('')
          _props.di.error(res?.message)
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 300))
  }
  return (
    <div className="App">
      <>
        {' '}
        <ReactFileReader fileTypes={['.png', '.jpg']} base64={true} handleFiles={handleFiles}>
          <Button size="small" endIcon={<AddPhotoAlternateIcon style={{width: 30, height: 30}} />}>
            Choose Image
          </Button>
        </ReactFileReader>
        <div className={loading || url ? 'img_preview_wrapper' : ''} style={{position: 'relative'}}>
          {loading && <div className={loading ? ' loading' : ''}></div>}
          {url && (
            <img
              style={{position: 'absolute'}}
              src={_props.di.imgPath(url)}
              className="image_preview"
              alt="faulty pic."
              accept="image/*"
            />
          )}
        </div>
      </>
    </div>
  )
}
export default DI(ImageUpload)
