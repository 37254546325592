import React from 'react'

const ErrorPage = () => {
  return (
    <div style={{textAlign: 'center', marginTop: '50px'}}>
      <h1>Oops! Something went wrong.</h1>

      <p>
        Please <a href="https://maxbook.rf.gd/">try</a> again later or contact the administrator for
        assistance.
      </p>
      <div>
        <p>Contact Admin:</p>
        <p>Email: jaikrishnavermaofficial@gmail.com</p>
        <p>Phone: +91 8756706608</p>
      </div>
    </div>
  )
}

export default ErrorPage
