import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {TextField} from '@mui/material'
import {MyContext} from '../../staticsData/MyContext'
import {createdAt, idGenerate, noRef} from '../utility/GlobalFunctons'
import {useSnackbar} from 'notistack'
import {useNavigate} from 'react-router-dom'
import CounterLoading from '../Fallback/CounterLoading'
import {DI} from '../../Core/DependencyInjecttion'
import {urlFetchCalls} from '../../constant'
const startTimer = 5
const SelectionForm = (_props) => {
  const [state, setState] = React.useState({
    userName: '',
    examId: '',
    paperId: '',
    loading: false
  })
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const {userName, examId, paperId} = state
  const [exams, setExams] = React.useState([])
  const [papers, setPapers] = React.useState([])
  const {
    centralState: {users},
    setCentralState
  } = React.useContext(MyContext)
  const session = _props?.di?.state?.session

  const submitted = (e) => {
    e.preventDefault()
    const user_id = session?.user_id
    if (!user_id) {
      return enqueueSnackbar('Please Login!', {
        preventDuplicate: false,
        variant: 'error'
      })
    }
    setState((prev) => {
      return {...prev, loading: true}
    })
    setTimeout(() => {
      navigate(`test/${user_id}/${paperId}`)
    }, (startTimer + 1) * 1000)
  }
  React.useEffect(() => getExam(), [])
  const getExam = () => {
    _props.di
      .GET(urlFetchCalls.get.get_exams, {
        search: ''
      })
      .then((res) => {
        if (res.success) {
          setExams(res?.data ?? [])
        } else {
          _props.di.error(res?.message)
        }
      })
  }
  React.useEffect(() => {
    getPapers(state?.examId)
  }, [state])
  const getPapers = (exam_id) => {
    _props.di
      .GET(urlFetchCalls.get.get_papers, {
        exam_id
      })
      .then((res) => {
        if (res.success) {
          setPapers(res?.data ?? [])
        } else {
          _props.di.error(res?.message)
        }
      })
  }

  if (state.loading)
    return (
      <div className="form loadingCircle-Wrap">
        <CounterLoading
          timer={startTimer}
          callBack={() => {}}
          style={
            {
              // width:"50vw"
            }
          }
          message="Get Ready For Test."
        />
      </div>
    )

  return (
    <div className="form">
      <form onSubmit={submitted}>
        <div style={{paddingLeft: '10px'}}>
          <h2>Give Free Moc Test</h2>
          <small>
            must remember your <b>username</b> for next time.
          </small>
        </div>
        {/* <FormControl   className="hero_input" sx={{ m: 1, minWidth: 120 }}>
          <TextField
            required
            id="outlined-basic"
            label="Username"
            variant="outlined"
            placeholder="Tony3000"
            value={userName}
            onChange={(e) => setState({ ...state, userName: e.target.value })}
          />
          <FormHelperText>Enter Unique Username</FormHelperText>
        </FormControl> */}
        <FormControl className="hero_input" sx={{m: 1, minWidth: 120}}>
          <InputLabel id="demo-simple-select-helper-label">Exams</InputLabel>
          <Select
            sx={{minWidth: '12vw'}}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={examId}
            placeholder="UPSC"
            onChange={(e) => e.target.value && setState({...state, examId: e.target.value})}
            label="Exams"
            required
          >
            <MenuItem value="">
              <em>Select any Exam</em>
            </MenuItem>
            {exams.map((item) => (
              <MenuItem value={item.id}>{item.exam_name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>Select any Exam</FormHelperText>
        </FormControl>
        <FormControl className="hero_input" sx={{m: 1, minWidth: 120}}>
          <InputLabel id="demo-simple-select-helper-label">Papers</InputLabel>
          <Select
            sx={{minWidth: '12vw'}}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            placeholder="Pre"
            value={paperId}
            onChange={(e) => e.target.value && setState({...state, paperId: e.target.value})}
            label="Papers"
            required
          >
            <MenuItem value="">
              <em>{`${papers.length ? 'Select any Paper' : 'No Paper Found'}`}</em>
            </MenuItem>
            {papers.map((item) => (
              <MenuItem value={item.id}>{item.paper_name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>Select any Paper</FormHelperText>
        </FormControl>
        <FormControl sx={{m: 1, minWidth: 120}}>
          <button type="submit" class="button-glitch hero_input_button" role="button">
            Take Test
          </button>
        </FormControl>
      </form>
    </div>
  )
}
export default DI(SelectionForm)
