
import CreatePaper from "./CreatePaper";
import ExamsList from "./ExamsList";
import PaperList from "./PaperList";
import AddQuestion from "./AddQuestion";
import ShowQuestion from "./ShowQuestion";
import FolderIcon from '@mui/icons-material/Folder';
import TopicIcon from '@mui/icons-material/Topic';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
export const panelChildren = [
  {
    path: "exams",
    element: <ExamsList />,
    icon: <FolderIcon />,
    inNav: true,
  },
  {
    path: "papers",
    element: <PaperList />,
    icon: <TopicIcon />,
    inNav: true,
  },
  {
    path: "view_questions",
    element: <ShowQuestion />,
    icon: <DescriptionIcon />,
    inNav: true,
  },
  {
    path: "create_paper",
    element: <CreatePaper />,
    icon: <PostAddIcon />,
    inNav: true,
  },
  {
    path: "add_question",
    element: <AddQuestion />,
    icon: <NoteAddIcon />,
    inNav: true,
  },
  {
    path: "*",
    element: <ExamsList />,
    inNav: false,
  },

];
export const HeadingFormat = (key) => {
  if(key && typeof key=="string")
  return key.split("_").map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(" ");
  return key
};
