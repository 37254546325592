import React, {useState} from 'react'
import {status_details} from '../../staticsData/initialData'
import {Button} from '@mui/material'
import {DI} from '../../Core/DependencyInjecttion'

const QuestionNavigation = DI((_props) => {
 const {questions, navigateQuestion, currentQuestion, handleOpen } = _props
  const session = _props?.di?.state?.session
  return (
    <>
      <div className="test_profile">
        <img
          style={{maxWidth: '14vh'}}
          src={process.env.PUBLIC_URL + '/person.png'}
          alt="user_image"
        />
        <h2>{session?.name??'Error User'}</h2>
      </div>
      {/* signs */}
      <div className="test_signdetails">
        {status_details.map((item) => {
          const counts = questions.filter((x) => x.statusId === item.id).length
          return (
            <div
              key={item.id}
              className={`test_signswithlabel ${item.status.length < 25 ? 'sign_width' : ''}`}
            >
              <div className={`sign sign_${item.id}`}>{counts < 10 ? '0' + counts : counts}</div>
              <p className="test_status_text">{item.status}</p>
            </div>
          )
        })}
      </div>
      {/* answers */}
      <h4 className="text_choose_heading">Choose a Question</h4>
      <div className="test_questions_no">
        {questions.map((item, index) => {
          return (
            <div
              key={`${index}_${currentQuestion}`}
              onClick={() => navigateQuestion(index)}
              className={`sign_questions_wrap ${index == currentQuestion ? 'active' : ''}`}
            >
              <div className={`sign ${'sign_' + questions[index].statusId} sign_questions`}>
                {index + 1}
              </div>
            </div>
          )
        })}
      </div>
      <div className="test_footer" style={{justifyContent: 'flex-end'}}>
        <Button onClick={handleOpen} variant="contained" sx={{background: 'red'}}>
          FINAL SUBMIT
        </Button>
      </div>
    </>
  )
})

export default QuestionNavigation
